import {useNotification} from '@hconnect/uikit'
import {AxiosError} from 'axios'
import {useTranslation} from 'react-i18next'
import {useMutation, UseMutationOptions, useQueryClient} from 'react-query'

import {isTask} from '../../common/utils/eventType'
import {prepareEventFormData} from '../../common/utils/formData'
import {EventCreate, EventUpdate, ShiftEvent} from '../../types/shiftHandover.types'
import {useConfig} from '../useConfig'

import {createEvent} from './createEvent'
import {useApi} from './useApi'

export const useEventCreate = (
  options?: UseMutationOptions<ShiftEvent, AxiosError, EventCreate>
) => {
  const queryClient = useQueryClient()
  const {axiosInstance} = useApi()
  const {plantId, timezone} = useConfig()
  const {t} = useTranslation()
  const {notify} = useNotification()

  return useMutation(
    (event: EventCreate) => {
      const scheduleRecurringTask = isTask(event) && !!event.repetitionInfo
      return createEvent(axiosInstance, plantId, event, timezone, scheduleRecurringTask)
    },
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        if (isTask(data) && data.isChecklistsCreationFailed) {
          notify('error', t('shiftEvent.checklist.action.createFailed'))
        }
        await queryClient.invalidateQueries()
        await options?.onSuccess?.(data, variables, context)
      }
    }
  )
}

/**
 * Special case for converting a simple task to recurring task
 * For editing a simple task to add recurring info, we 'POST' the data to tasks/schedules api
 * to handle the scheduling of repetitive Task independent from original create Event logic
 * the backend team decided to use a dedicated endpoint with the same DTO
 */
export const useCreateNonRecToRecEvent = (
  options?: UseMutationOptions<ShiftEvent, AxiosError, EventUpdate>
) => {
  const {axiosInstance} = useApi()
  const queryClient = useQueryClient()
  const {plantId} = useConfig()
  const {t} = useTranslation()
  const {notify} = useNotification()

  return useMutation(
    async (event: EventUpdate) => {
      const formData = await prepareEventFormData(event, ['attachments'])

      const path = `/shifthandover/${plantId}/tasks/schedules`
      const response = await axiosInstance.post<ShiftEvent>(path, formData)
      return response.data
    },
    {
      ...options,
      onSuccess: async (data) => {
        if (isTask(data) && data.isChecklistsCreationFailed) {
          notify('error', t('shiftEvent.checklist.action.createFailed'))
        }
        await queryClient.invalidateQueries()
      }
    }
  )
}
