import {ContentBox} from '@hconnect/common/components/ContentBox'
import {shouldNotBeEmpty, ValidatorConfig, validateAll} from '@hconnect/common/validators'
import {Check, Close} from '@mui/icons-material'
import {Box, Button} from '@mui/material'
import React, {FC, useCallback, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {InstructionType, DocumentUpdate, Instruction} from '../../types/documents.types'
import {
  maxStringLengthValidator,
  shouldNotBeEmptyLinkValidator,
  validToValidator,
  shouldNotBeEmptyAttachmentValidator
} from '../../validators'

import {DocumentForm} from './DocumentForm'

const emptyMap = new Map()

const documentValidatorConfig: ValidatorConfig<DocumentUpdate> = new Map([
  ['title', maxStringLengthValidator<DocumentUpdate>()],
  ['processStage', shouldNotBeEmpty<DocumentUpdate>()],
  ['validFrom', shouldNotBeEmpty<DocumentUpdate>()],
  ['validTo', validToValidator],
  ['link', shouldNotBeEmptyLinkValidator],
  ['newAttachment', shouldNotBeEmptyAttachmentValidator]
])

type State = {
  originalItem: Partial<Instruction>
  item: Partial<Instruction>
  showValidation: boolean
  validationError: Map<keyof Instruction, string>
}

type DocumentFormContainerProps = {
  doClose(): void
  doSubmit(item: Instruction): void
  item?: Partial<Instruction>
}

export const DocumentFormContainer: FC<DocumentFormContainerProps> = ({
  item: originalItem = {type: InstructionType.PDF},
  doClose,
  doSubmit: parentDoSubmit
}) => {
  const {t} = useTranslation()
  const [state, setState] = useState<State>({
    originalItem,
    item: originalItem,
    showValidation: false,
    validationError: emptyMap
  })

  const item = state.item
  const disableSubmit = state.showValidation && state.validationError.size > 0

  const doSubmit = () => {
    const validationError = validateAll<DocumentUpdate>(documentValidatorConfig, item)

    if (validationError.size === 0) {
      parentDoSubmit(item as Instruction)
    } else {
      setState((currentState: State) => ({
        ...currentState,
        showValidation: true,
        validationError
      }))
    }
  }

  const updateMultiPart = useCallback((delta: Partial<Instruction>) => {
    setState((currentState: State) => {
      const nextItem = {
        ...currentState.item,
        ...delta
      } as Instruction

      const validationError = currentState.showValidation
        ? validateAll(documentValidatorConfig, nextItem)
        : currentState.validationError

      return {
        ...currentState,
        item: nextItem,
        validationError
      }
    })
  }, [])

  return (
    <ContentBox data-test-id="document-form" bodyWithPadding mode="max100PercentOfParentHeight">
      <DocumentForm
        item={item}
        updateMultiPart={updateMultiPart}
        validationError={state.showValidation ? state.validationError : emptyMap}
      />
      <Box mt={3} display="flex" justifyContent="flex-end" gap={2}>
        <Button
          startIcon={<Close />}
          variant="text"
          onClick={doClose}
          data-test-id="document-form-cancel-button"
        >
          {t('action.cancel')}
        </Button>
        <Button
          startIcon={<Check />}
          disabled={disableSubmit}
          color="primary"
          variant="contained"
          onClick={doSubmit}
          data-test-id="document-save-button"
        >
          {t('action.save')}
        </Button>
      </Box>
    </ContentBox>
  )
}
