import {AxiosInstance} from 'axios'

import {prepareEventFormData} from '../../common/utils/formData'
import {getEventBackendEndpoint} from '../../consts'
import {mapShiftEvent} from '../../mappers'
import {EventCreate, ShiftEventDto} from '../../types/shiftHandover.types'

export const createEvent = async (
  axiosInstance: AxiosInstance,
  plantId: string,
  event: EventCreate,
  timezone: string,
  scheduleRecurringTask: boolean | undefined = false
) => {
  const formData = await prepareEventFormData(event)
  const path = getEventBackendEndpoint(plantId, event.eventType, scheduleRecurringTask)

  const response = await axiosInstance.post<ShiftEventDto>(path, formData)
  return mapShiftEvent(response.data, timezone)
}
