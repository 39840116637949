import {ContentMode} from '@hconnect/common/components/ContentBox'
import {SideCardModes} from '@hconnect/common/components/eventsList/types'
import {
  fetchMainEquipmentByPxTrendTagSuggestion,
  getQueryKeyMainEquipmentByPxTrendTagSuggestion
} from '@hconnect/common/services'
import {
  DateRange,
  EquipmentData,
  RunningTime,
  RunningTimesEquipmentData,
  RunningTimeStoppage,
  StoppageKind
} from '@hconnect/common/types'
import {useNotification} from '@hconnect/uikit'
import React, {useCallback, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useQueryClient} from 'react-query'

import {StackedBarChart} from '../../components/charts/StackedBarChart'
import {RunningTimesSummary} from '../../components/runningTimes'
import {useApi} from '../../hooks/api/useApi'
import {useRunningTimes} from '../../hooks/api/useRunningTimes'
import {useConfig} from '../../hooks/useConfig'
import {ShiftEvent} from '../../types/shiftHandover.types'
import {generateStoppageDefaultData} from '../../utils'
import {SideCard} from '../common/SideCard'

type RunningTimesSummaryContainerProps = {
  contentMode: ContentMode
  isPrinting?: boolean
  isLoading?: boolean
  dateRange: DateRange
}

export const RunningTimesSummaryContainer: React.FC<RunningTimesSummaryContainerProps> = (
  props
) => {
  const {t} = useTranslation()
  const {notify} = useNotification()
  const {plantId} = useConfig()
  const [sideCardMode, setSideCardMode] = useState<SideCardModes<ShiftEvent> | null>(null)
  const [equipmentName, setEquipmentName] = useState<string>()

  const queryClient = useQueryClient()
  const {axiosInstance} = useApi()

  const {
    data: runningTimePerformances,
    isLoading: isRunningTimePerformanceLoading,
    isError: isRunningTimePerformanceError
  } = useRunningTimes(props.dateRange)

  const onCreationSuccess = useCallback(() => {
    notify('success', t('summary.action.successfullyCreated'))
  }, [notify, t])

  const getStackedBarChartComponent = useCallback(
    (equipment: RunningTimesEquipmentData, runningTimes: RunningTime[]) => {
      /**
       *  the equipment is previously typed as RunningTimesEquipmentData
       *  and EquipmentData is a subset of RunningTimesEquipmentData */
      const equipmentDataFromRunningTimesEquipment: EquipmentData = equipment
      return (
        <StackedBarChart
          dateRange={props.dateRange}
          handleStoppageClick={async (runningTime: RunningTime, stoppage?: RunningTimeStoppage) => {
            if (stoppage?.cockpitStoppageId) {
              setSideCardMode({
                mode: 'detailsMode',
                itemId: stoppage.cockpitStoppageId
              })
            } else {
              if (runningTime.begin && equipment) {
                let suggestedMainEquipment: EquipmentData | undefined = undefined
                try {
                  suggestedMainEquipment = await queryClient.fetchQuery({
                    queryKey: getQueryKeyMainEquipmentByPxTrendTagSuggestion(plantId, equipment.id),
                    queryFn: async () =>
                      fetchMainEquipmentByPxTrendTagSuggestion({
                        plantId,
                        pxTrendTag: equipment.id,
                        axiosInstance
                      }),
                    staleTime: 1000 * 60 * 15
                  })
                } catch (error) {
                  console.error('Error fetching main equipment by px trend tag suggestion', error)
                }
                const defaultValue = generateStoppageDefaultData({
                  runningTime,
                  equipment,
                  suggestedMainEquipment
                })
                setSideCardMode({
                  mode: 'createMode',
                  preSetEventType: StoppageKind.Incident,
                  initialData: defaultValue
                })
                setEquipmentName(equipment.text)
              } else {
                setSideCardMode({mode: 'createMode', preSetEventType: StoppageKind.Incident})
              }
            }
          }}
          data={runningTimes}
          equipment={equipmentDataFromRunningTimesEquipment}
        />
      )
    },
    [props.dateRange, axiosInstance, plantId, queryClient]
  )

  return sideCardMode ? (
    <SideCard
      {...sideCardMode}
      setMode={(options: SideCardModes<ShiftEvent>) => {
        if (options.mode === 'detailsMode') {
          setSideCardMode(null)
        } else {
          setSideCardMode(options)
        }
      }}
      doClose={() => {
        setSideCardMode(null)
      }}
      data-test-id="summary-page-stoppage"
      initialData={sideCardMode.mode === 'createMode' ? sideCardMode.initialData : undefined}
      onCreationSuccess={onCreationSuccess}
      eventTypeLabelPrefix={equipmentName}
      hideStoppageDateType={true}
    />
  ) : (
    <RunningTimesSummary
      {...props}
      runningTimePerformances={runningTimePerformances}
      isLoading={props.isLoading || isRunningTimePerformanceLoading}
      getStackedBarChartComponent={getStackedBarChartComponent}
      plantId={plantId}
      isError={isRunningTimePerformanceError}
    />
  )
}
