import {DateRange, FilterTimeRange} from '@hconnect/common/types'
import {Shift} from '@hconnect/common/types/shift.types'
import {AxiosError} from 'axios'
import {Moment} from 'moment-timezone'
import {useQuery, UseQueryOptions} from 'react-query'

import {
  FilterOptions,
  getQuickSelectFilter,
  QuickSelectSlots
} from '../../common/utils/filterParameter'
import {mapShiftEvent} from '../../mappers'
import {
  EventSearchCriteria,
  EventSearchResult,
  SortBy,
  Iso8601,
  SortOrder,
  EventSearchDto
} from '../../types/shiftHandover.types'
import {
  stoppageCodeSearchParamsToFilterParams,
  maintenanceNotificationSearchParamsToFilterParams
} from '../../utils/stoppageHelper'
import {useConfig} from '../useConfig'

import {useApi} from './useApi'

export const QueryKey = 'events-search'

export const generateTimeRange = (
  filterTimeRange: DateRange | QuickSelectSlots | undefined,
  shifts: Shift[],
  plantNow: () => Moment
): FilterTimeRange => {
  let timeRange: {
    startDate: Iso8601 | null
    endDate: Iso8601 | null
  }

  if (typeof filterTimeRange === 'object') {
    timeRange = {
      startDate: filterTimeRange.startDate.toISOString(),
      endDate: filterTimeRange.endDate.toISOString()
    }
  } else if (typeof filterTimeRange === 'string') {
    const [startDate, endDate] = getQuickSelectFilter(filterTimeRange, shifts, plantNow())
    timeRange = {startDate: startDate.toISOString(), endDate: endDate.toISOString()}
  } else {
    timeRange = {startDate: null, endDate: null}
  }

  return timeRange
}

export const useEventsSearch = (
  input: FilterOptions,
  sortBy: SortBy = SortBy.Details,
  sortOrder?: SortOrder,
  pageNumber = 0,
  itemsPerPage = 50,
  options?: UseQueryOptions<EventSearchResult, AxiosError>
) => {
  const {axiosInstance} = useApi()
  const config = useConfig()
  const {plantNow, shifts, timezone, plantId} = config

  const timeRange: FilterTimeRange = generateTimeRange(input.timeRange, shifts, plantNow)

  return useQuery<EventSearchResult, AxiosError>(
    [QueryKey, input, timeRange, pageNumber, itemsPerPage, timezone, sortBy, sortOrder, plantId],
    async ({signal: abortSignal}) => {
      const settings: EventSearchCriteria = {
        timeRange,
        processStage: input.processStage,
        equipment: input.equipment,
        mainEquipment: input.mainEquipment,
        eventType: input.eventType,
        priority: input.priority,
        status: input.status,
        category: input.category,
        pageNumber,
        itemsPerPage,
        sortBy,
        sortOrder,
        stoppageCodesFilter: stoppageCodeSearchParamsToFilterParams(input.stoppageCode),
        freeText: input.freeText,
        maintenanceNotificationExists: maintenanceNotificationSearchParamsToFilterParams(
          input.maintenanceNotificationExists
        )
      }

      const response = await axiosInstance.post<EventSearchDto>(
        `/shifthandover/${plantId}/search/events`,
        settings,
        {signal: abortSignal}
      )
      return {
        ...response.data,
        events: (response.data.events || []).map((event) => mapShiftEvent(event, timezone))
      }
    },
    {
      ...options
    }
  )
}
