import moment from 'moment-timezone'

import {
  DocumentUpdate,
  Instruction,
  InstructionDto,
  InstructionPayload,
  InstructionStatus,
  InstructionType
} from '../types/documents.types'

export const prepareInstructionFormData = (instruction: DocumentUpdate): FormData => {
  const formData = new FormData()
  const instructionPayload: InstructionPayload = {
    title: instruction.title,
    processStage: instruction.processStage,
    category: instruction.category,
    validFrom: instruction.validFrom.toISOString(),
    validTo: instruction.validTo?.toISOString(),
    link: instruction.link,
    attachment: instruction.newAttachment
  }
  const keys = Object.keys(instructionPayload)
  for (const key of keys) {
    const value = instructionPayload[key]
    if (value) {
      switch (key) {
        case 'priority':
          if (value !== 0) {
            formData.append(key, value as string | Blob)
          }
          break
        default:
          formData.append(key, value as string | Blob)
      }
    }
  }

  return formData
}

export const mapInstructionDto = (data: InstructionDto, timezone: string): Instruction => {
  const validFrom = moment.utc(data.validFrom).tz(timezone)
  const validTo = data.validTo ? moment.utc(data.validTo).tz(timezone) : undefined

  let status: InstructionStatus
  let deleteDayCounter: number | undefined

  if (moment().isBefore(validFrom)) {
    status = InstructionStatus.UPCOMING
  } else if (validTo && moment().isAfter(validTo)) {
    status = InstructionStatus.EXPIRED
    deleteDayCounter = validTo.clone().add(31, 'days').diff(moment().tz(timezone), 'days')
  } else {
    status = InstructionStatus.ACTIVE
  }

  return {
    ...data,
    status,
    deleteDayCounter,
    validFrom,
    validTo,
    updatedAt: moment.utc(data.updatedAt).tz(timezone),
    createdAt: moment.utc(data.createdAt).tz(timezone),
    type: data.attachment ? InstructionType.PDF : InstructionType.LINK
  }
}
