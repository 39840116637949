import {AxiosError} from 'axios'
import moment from 'moment-timezone'
import {useMutation, UseMutationOptions, useQueryClient} from 'react-query'

import {prepareInstructionFormData} from '../../mappers'
import {DocumentUpdate, Instruction} from '../../types/documents.types'
import {useConfig} from '../useConfig'

import {useApi} from './useApi'

const INSTRUCTIONS_TENANT_ID = 'shiftHandover'

export const useInstructionEdit = (
  options?: UseMutationOptions<Instruction, AxiosError, DocumentUpdate>
) => {
  const queryClient = useQueryClient()
  const {axiosInstance} = useApi()
  const {plantId, timezone} = useConfig()

  return useMutation(
    async (document: DocumentUpdate) => {
      const formData = prepareInstructionFormData(document)
      const response = await axiosInstance.put<DocumentUpdate>(
        `/instructions/${INSTRUCTIONS_TENANT_ID}/plants/${plantId}/instructions/${document.id}`,
        formData
      )
      return {
        ...response.data,
        validFrom: moment.utc(response.data.validFrom).tz(timezone),
        validTo: moment.utc(response.data.validTo).tz(timezone),
        createdAt: moment.utc(response.data.createdAt).tz(timezone),
        updatedAt: moment.utc(response.data.updatedAt).tz(timezone)
      }
    },
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        await queryClient.invalidateQueries()
        await options?.onSuccess?.(data, variables, context)
      }
    }
  )
}
