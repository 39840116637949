import {ContentBox} from '@hconnect/common/components/ContentBox'
import {ExpertSystemWrapper} from '@hconnect/common/components/expertSystem'
import {ProductionContainer} from '@hconnect/common/components/productionContainer'
import {Grid, Skeleton, Theme, useMediaQuery} from '@mui/material'
import React, {FC} from 'react'
import {useTranslation} from 'react-i18next'

import {FilterOptions} from '../../common/utils/filterParameter'
import {useExpertSystemData} from '../../hooks/api/useExpertSystemData'
import {useMaterials} from '../../hooks/api/useMaterials'
import {useConfig} from '../../hooks/useConfig'
import {useFilterDatePicker} from '../../hooks/useDatePicker'
import {SummaryPageData} from '../../types/shiftHandover.types'

import {RunningTimesSummaryContainer} from './RunningTimesSummaryContainer'
import {ShiftComments} from './ShiftComments'
import {ShiftSummaryEvents} from './ShiftSummaryEvents'
import {SiloFillsContainer} from './SiloFillsContainer'

type ShiftSummaryViewProps = {
  isMobileFilterOpen: boolean
  isLoading: boolean
  isFetching: boolean
  summaryPageData?: SummaryPageData
  isProductionVolumesAllowed: boolean
  filterOptions: FilterOptions
}

const sampleContent = (lines: number) => {
  const result: React.ReactNode[] = []
  for (let i = 0; i < lines; i++) {
    result.push(<Skeleton animation={false} key={i} />)
  }

  return result
}

export const ShiftSummaryView: FC<ShiftSummaryViewProps> = ({
  isMobileFilterOpen,
  isLoading,
  isFetching,
  summaryPageData,
  isProductionVolumesAllowed,
  filterOptions: filterOptionsProps
}) => {
  const {t} = useTranslation()
  const {pxTrendAvailable} = useConfig()
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
  const {isSingleShift, quickSelectSlot, timeRange, dateRange} = useFilterDatePicker()
  const {data: materialsData, isLoading: isMaterialsDataLoading} = useMaterials(timeRange.endDate, {
    enabled: isSingleShift
  })

  const {timezone} = useConfig()

  const contentMode = 'contentDependentHeight'
  const isSameMonth = dateRange.startDate.isSame(dateRange.endDate, 'month')
  const isExpertSystemEnabled =
    !['last30days', 'next30days'].includes(quickSelectSlot) && isSameMonth

  const {
    data: expertSystemEquipments,
    error,
    isLoading: expertSystemEquipmentsLoading,
    isFetching: expertSystemEquipmentsFetching
  } = useExpertSystemData(
    {
      startDate: timeRange.startDate,
      endDate: timeRange.endDate
    },
    {
      enabled: isExpertSystemEnabled
    }
  )

  const expertSystemEquipmentsFetchingOrLoading =
    expertSystemEquipmentsFetching || expertSystemEquipmentsLoading

  const isLoadingOrFetching = isLoading || isFetching

  return !isMobileFilterOpen ? (
    <Grid container spacing={2} width="100%" wrap={isMobile ? undefined : 'nowrap'}>
      <Grid item container spacing={2} direction="column" sm={12} md={4}>
        <Grid item>
          <ShiftComments
            timeRange={timeRange}
            isSingleShift={isSingleShift}
            contentMode={contentMode}
            timezone={timezone}
          />
        </Grid>

        <Grid item>
          <ExpertSystemWrapper
            isSameMonth={isExpertSystemEnabled}
            expertSystemEquipments={expertSystemEquipments}
            isLoading={isLoadingOrFetching || expertSystemEquipmentsFetchingOrLoading}
            error={error}
          />
        </Grid>

        <Grid item>
          {isProductionVolumesAllowed ? (
            <ProductionContainer
              isLoading={isLoadingOrFetching || isMaterialsDataLoading}
              materials={materialsData}
              productionVolumes={summaryPageData?.productionVolumesResult?.productionVolumes}
              contentMode={contentMode}
              timeRange={timeRange}
              timezone={timezone}
            />
          ) : (
            <ContentBox
              title={t('summary.label.ProductionVolumes')}
              mode={contentMode}
              bodyWithPadding
              data-test-id="shift-summary-page-production-volumes-placeholder"
            >
              {t('comingSoon')}
              {sampleContent(15)}
            </ContentBox>
          )}
        </Grid>
      </Grid>

      <Grid item container spacing={2} direction="column" sm={12} md={4}>
        {pxTrendAvailable && (
          <Grid item>
            <RunningTimesSummaryContainer
              contentMode={contentMode}
              isLoading={isLoading}
              dateRange={dateRange}
            />
          </Grid>
        )}

        <Grid item>
          <SiloFillsContainer
            materials={materialsData}
            contentMode={contentMode}
            isSingleShift={isSingleShift}
            timeRange={timeRange}
          />
        </Grid>
      </Grid>

      <Grid item container spacing={2} direction="column" sm={12} md={4}>
        <Grid item>
          <ShiftSummaryEvents filterOptions={filterOptionsProps} />
        </Grid>
      </Grid>
    </Grid>
  ) : (
    <></>
  )
}
