import {FlexPage} from '@hconnect/common/components/FlexPage'
import {useWidth} from '@hconnect/common/hooks/useWidth'
import {Print as PrintIcon} from '@mui/icons-material'
import {Button, Grid} from '@mui/material'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'

import {downloadBlob} from '../common/utils/downloadHelper'
import {
  defaultDesktop,
  desktopShiftSummaryFilterOptions,
  DesktopShiftSummaryFilterOptionsKeys,
  Page
} from '../common/utils/filterParameter'
import {SHIFT_SUMMARY_TITLE_ID} from '../consts'
import {ShiftSummaryView} from '../containers/shiftSummary/ShiftSummaryView'
import {useShiftSummary} from '../hooks/api/useShiftSummary'
import {useShiftSummaryDocument} from '../hooks/api/useShiftSummaryDocument'
import {useCancelInactiveQueries} from '../hooks/useCancelInactiveQueries'
import {useFilterDatePicker} from '../hooks/useDatePicker'
import {usePageFilters} from '../hooks/useEventsFilter'
import {useFeatureFlag} from '../hooks/useFeatureFlag'
import {EventsFilterBar} from '../layouts/FilterBar'
import {getButtonOutlineWhite} from '../styles/common'

export const ShiftSummaryPage: React.FC = () => {
  const {
    t,
    i18n: {language}
  } = useTranslation()
  const breakpoint = useWidth()
  const [filterOptions, setFilters] = usePageFilters(Page.Summary)
  const {timeRange, getDateRange, isSingleShift} = useFilterDatePicker()
  const [isMobileFilterOpen, openMobileFilter] = useState(false)
  const [isPreparingSummary, setIsPreparingSummary] = useState(false)

  const extendedFilterOptions = {...filterOptions}
  if (!extendedFilterOptions.timeRange) {
    extendedFilterOptions.timeRange = getDateRange()
  }

  const isProductionVolumesAllowed = useFeatureFlag('productionVolumes')
  const isSmall = breakpoint === 'xs' || breakpoint === 'sm'

  const {
    data: summaryPageData,
    isLoading: summaryDataLoading,
    isFetching: summaryDataFetching
  } = useShiftSummary(extendedFilterOptions.timeRange, {
    enabled: isProductionVolumesAllowed
  })

  const getSummaryPdf = useShiftSummaryDocument({
    onSuccess: (file: Blob) =>
      downloadBlob(file, `${t('summary.label.shiftSummaryReportFileName')}.pdf`),
    onSettled: () => setIsPreparingSummary(false)
  })

  const handleGetSummaryPdf = () => {
    setIsPreparingSummary(true)
    getSummaryPdf.mutate({
      timeRange,
      reportLanguage: language,
      includeProductionVolumes: isSingleShift
    })
  }

  useCancelInactiveQueries()

  return (
    <FlexPage
      appName="Cockpit"
      title={isMobileFilterOpen ? t('shiftEvent.action.filter') : t('summary.pageName')}
      titleId={SHIFT_SUMMARY_TITLE_ID}
      sxActionContent={isMobileFilterOpen ? {width: '100%'} : undefined}
      headerActionContent={
        <Grid container alignItems="center">
          <Grid item xs={isMobileFilterOpen ? 12 : undefined}>
            <EventsFilterBar<DesktopShiftSummaryFilterOptionsKeys>
              activeSettings={filterOptions}
              isCollapsible={isSmall}
              options={desktopShiftSummaryFilterOptions}
              onChange={(nextFilterOptions) => {
                setFilters(nextFilterOptions)
              }}
              openMobileFilter={openMobileFilter}
              isMobileFilterOpen={isMobileFilterOpen}
              defaultFilters={defaultDesktop}
            />
          </Grid>
          {!isMobileFilterOpen && (
            <Grid item>
              <Button
                data-test-id="summary-print-button"
                onClick={handleGetSummaryPdf}
                sx={(theme) => ({
                  margin: '8px 10px',
                  ...getButtonOutlineWhite(theme),
                  '@media print': {
                    display: 'none'
                  },
                  '&.MuiButton-root:first-of-type': {
                    margin: '8px 10px'
                  }
                })}
                disabled={isPreparingSummary}
                variant="text"
              >
                <PrintIcon />
              </Button>
            </Grid>
          )}
        </Grid>
      }
    >
      <ShiftSummaryView
        isMobileFilterOpen={isMobileFilterOpen}
        isLoading={summaryDataLoading}
        isFetching={summaryDataFetching}
        summaryPageData={summaryPageData}
        isProductionVolumesAllowed={isProductionVolumesAllowed}
        filterOptions={extendedFilterOptions}
      />
    </FlexPage>
  )
}
