import {AxiosError} from 'axios'
import {useMutation, UseMutationOptions, useQueryClient} from 'react-query'

import {INSTRUCTIONS_TENANT_ID} from '../../consts'
import {prepareInstructionFormData} from '../../mappers'
import {DocumentUpdate, Instruction} from '../../types/documents.types'
import {useConfig} from '../useConfig'

import {useApi} from './useApi'

export const useDocumentCreate = (
  options?: UseMutationOptions<Instruction, AxiosError, DocumentUpdate>
) => {
  const queryClient = useQueryClient()
  const {axiosInstance} = useApi()
  const {plantId} = useConfig()

  return useMutation(
    async (event: DocumentUpdate) => {
      const formData = prepareInstructionFormData(event)
      const response = await axiosInstance.post<DocumentUpdate>(
        `/instructions/${INSTRUCTIONS_TENANT_ID}/plants/${plantId}/instructions`,
        formData
      )
      return response.data
    },
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        await queryClient.invalidateQueries()
        await options?.onSuccess?.(data, variables, context)
      }
    }
  )
}
