import {ParticularShift, Shift} from '@hconnect/common/types'
import {Moment} from 'moment-timezone'
import React, {useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import {getShift} from '../../common/utils/shift'
import {useConfig} from '../../hooks/useConfig'

import {SimpleShiftPicker} from './SimpleShiftPicker'

type ShiftPickerProps = {
  error?: boolean
  enableSelectRange?: boolean
  localDate: Moment
  localStartDate?: Moment
  label?: React.ReactNode
  onChange: (value: ParticularShift | [ParticularShift, ParticularShift]) => void
}

const generateShiftDate = (date: Moment, shifts: Shift[]) => {
  const selectedShift = getShift(date, shifts)
  return selectedShift.startDate
}

export const ShiftPicker: React.FC<ShiftPickerProps> = ({
  localDate,
  localStartDate,
  label,
  error,
  ...rest
}) => {
  const {t} = useTranslation()
  const {shifts} = useConfig()
  if (!localDate) {
    throw new Error('missing localDate prop in ShiftPicker')
  }
  const date = useMemo(() => {
    return generateShiftDate(localDate, shifts)
  }, [localDate, shifts])
  const startDate = useMemo<Moment | undefined>(() => {
    if (!localStartDate) return
    return generateShiftDate(localStartDate, shifts)
  }, [localStartDate, shifts])
  return (
    <div data-test-id="simple-shift-picker" style={{height: '56px'}}>
      <SimpleShiftPicker
        error={error}
        date={startDate || date}
        dateRange={startDate && date ? [startDate, date] : undefined}
        noAutoClose={true}
        label={label || t('shiftEvent.label.shift')}
        {...rest}
      />
    </div>
  )
}
