import {isKilnRunning} from '@hconnect/hproduce/src/performanceDashboard/helpers'
import {FiberManualRecord as Circle} from '@mui/icons-material'
import {Box, Divider, Grid, Theme, Typography} from '@mui/material'
import {TFunction} from 'i18next'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {
  EquipmentRunningTimes,
  RunningTime,
  RunningTimesEquipmentData,
  RunningTimeType
} from '../../types'
import {getHoursMinutes} from '../../utils'
import {getTranslationKey} from '../../utils/translation.utils'
import {EquipmentLabel} from '../shiftEventLabels'

const statusStyle = {display: 'flex', alignItems: 'center'}

const getStatusIndicator = (
  t: TFunction,
  activeStatus?: RunningTimeType,
  translationPrefix?: string
) => (
  <>
    {activeStatus && isKilnRunning(activeStatus) && (
      <Typography
        mr={2}
        variant="caption"
        sx={(theme: Theme) => ({
          ...statusStyle,
          color: theme.palette.success.light
        })}
      >
        <Circle
          sx={(theme: Theme) => ({
            color: theme.palette.success.light,
            verticalAlign: 'text-bottom'
          })}
        />
        {t(getTranslationKey('runningTime.label.running', translationPrefix))}
      </Typography>
    )}
    {activeStatus && !isKilnRunning(activeStatus) && (
      <Typography
        mr={2}
        variant="caption"
        sx={(theme: Theme) => ({
          ...statusStyle,
          color: theme.palette.error.light
        })}
      >
        <Circle
          sx={(theme: Theme) => ({
            color: theme.palette.error.light,
            verticalAlign: 'text-bottom'
          })}
        />
        {t(getTranslationKey('runningTime.label.off', translationPrefix))}
      </Typography>
    )}
  </>
)

type Props = {
  equipment: RunningTimesEquipmentData
  runningTimePerformance?: EquipmentRunningTimes
  translationPrefix?: string
  hideDivider: boolean
  getStackedBarChartComponent?: (
    equipment: RunningTimesEquipmentData,
    runningTimes: RunningTime[]
  ) => React.ReactNode
  activeStatus?: RunningTimeType
  onClick?: () => void
}

export const EquipmentRunningTimesResult: React.FC<Props> = ({
  equipment,
  hideDivider,
  getStackedBarChartComponent,
  runningTimePerformance,
  translationPrefix,
  activeStatus,
  onClick
}) => {
  const {t} = useTranslation()

  return (
    <Grid
      data-test-id={`equipment-rt-${equipment.id}`}
      sx={[!!onClick && {cursor: 'pointer'}]}
      onClick={onClick}
    >
      <Box display="flex" alignItems="center" width="100%" py={1.5}>
        <Box width="100%">
          <Box mb={1} display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="subtitle1" display="flex" gap={2}>
              <EquipmentLabel withId={false} withText equipment={equipment} />
              {getStatusIndicator(t, activeStatus, translationPrefix)}
            </Typography>
            {runningTimePerformance?.totalStopped && (
              <Typography fontSize={12} color="text.secondary" display="flex">
                {/* show only hours and minutes */}
                {`${t(
                  getTranslationKey('summary.label.stoppedFor', translationPrefix)
                )} ${getHoursMinutes(runningTimePerformance.totalStopped)} h`}
              </Typography>
            )}
          </Box>
          <Box display={'flex'} alignItems={'stretch'} flexWrap={'wrap'} width="100%">
            <Box
              sx={{
                flex: '1',
                display: 'flex',
                alignItems: 'stretch',
                justifyContent: 'flex-start',
                borderRadius: 1,
                overflow: 'hidden'
              }}
            >
              {!runningTimePerformance
                ? null
                : getStackedBarChartComponent?.(
                    equipment,
                    runningTimePerformance?.runningTimes?.filter((rt) => rt.begin !== rt.end) || []
                  )}
            </Box>
          </Box>
        </Box>
      </Box>

      {!hideDivider && <Divider color="light" />}
    </Grid>
  )
}
