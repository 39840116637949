import {ViewMode} from '@hconnect/common/components/eventsList/types'
import {modeAfterClose} from '@hconnect/common/utils/cardDetailsHelpers'
import type {PaginationOptions} from '@hconnect/uikit/src/lib2'
import React, {useState} from 'react'
import {generatePath, useNavigate} from 'react-router-dom'

import {FilterOptions, filterSettingsToUrlParams, Page} from '../common/utils/filterParameter'
import {RecurringTasksView} from '../containers/recurringTasks'
import {useRepetitiveTasks} from '../hooks/api/useRepetitiveTasks'
import {useCancelInactiveQueries} from '../hooks/useCancelInactiveQueries'
import {useConfig} from '../hooks/useConfig'
import {useFilterDatePicker} from '../hooks/useDatePicker'
import {usePageFilters} from '../hooks/useEventsFilter'
import {useQueryParameterForViewMode, viewModeToUrlParams} from '../hooks/useQueryParameter'
import {routeToRecurringTasks} from '../routes'
import {ShiftEvent} from '../types/shiftHandover.types'
import {combineSearchParamsToQueryString} from '../utils'

const createUrl = (
  nextFilterOptions: FilterOptions,
  nextViewMode: ViewMode<ShiftEvent>,
  plantId: string
): string => {
  const filterParams = filterSettingsToUrlParams(nextFilterOptions)
  const viewModeParams = viewModeToUrlParams(nextViewMode)
  const queryString = combineSearchParamsToQueryString(filterParams, viewModeParams)
  return `${generatePath(routeToRecurringTasks, {plantId})}?${queryString}`
}

export const RecurringTasksPage = () => {
  const navigate = useNavigate()
  const viewMode = useQueryParameterForViewMode()
  const [pageNumber, changePage] = useState<number>(0)
  const [itemsPerPage, setItemsPerPage] = useState<number>(50)
  const [selectedSchedule, setSelectedSchedule] = useState<ShiftEvent | undefined>()
  const {plantId} = useConfig()
  const [filterOptions, setFilters] = usePageFilters(Page.RecurringTasks)
  const {getDateRange} = useFilterDatePicker()
  const extendedFilterOptions = {...filterOptions}
  if (!extendedFilterOptions.timeRange) {
    extendedFilterOptions.timeRange = getDateRange()
  }

  useCancelInactiveQueries()

  const {data, isLoading} = useRepetitiveTasks(filterOptions, itemsPerPage, pageNumber, {
    onSuccess: (items) => {
      if (
        viewMode?.mode !== 'createMode' &&
        viewMode?.mode !== 'tableOnlyMode' &&
        viewMode?.itemId
      ) {
        const selectedItem = items.taskSchedules.find(
          (item) => (item as ShiftEvent).id === viewMode.itemId
        )
        setSelectedSchedule(selectedItem as ShiftEvent)
      }
    }
  })

  const openDetailsMode = (schedule: ShiftEvent) => {
    navigate(createUrl(filterOptions, {mode: 'detailsMode', itemId: schedule.id}, plantId))
    setSelectedSchedule(schedule)
  }

  const doClose = (original?: ShiftEvent, editVersion?: ShiftEvent) => {
    const nextMode: ViewMode<ShiftEvent> = modeAfterClose<ShiftEvent>(original, editVersion)
    navigate(createUrl(filterOptions, nextMode, plantId))

    if (nextMode.mode === 'tableOnlyMode') {
      setSelectedSchedule(undefined)
    }
  }

  const onPaginationChange = (pageNumber: number, itemsPerPage: number) => {
    changePage(pageNumber)
    setItemsPerPage(itemsPerPage)
  }

  const paginationOptions: PaginationOptions = {
    onPaginationChange,
    page: pageNumber,
    rowsPerPage: itemsPerPage,
    totalDataLength: data?.totalItemCount ?? 0
  }

  return (
    <RecurringTasksView
      activeFilter={filterOptions}
      data={data}
      isLoading={isLoading}
      viewMode={viewMode}
      openDetailsMode={openDetailsMode}
      onSideCardClose={doClose}
      setViewMode={(nextViewMode) => {
        navigate(createUrl(filterOptions, nextViewMode, plantId))
      }}
      onFilterChange={(nextFilterOptions) => {
        changePage(0)
        setFilters(nextFilterOptions)
      }}
      paginationOptions={paginationOptions}
      selectedSchedule={selectedSchedule}
    />
  )
}
