import {sortBy} from 'lodash'
import moment, {Moment} from 'moment-timezone'

import {
  DowntimeStoppageCode,
  Shift,
  Config,
  FeatureFlag,
  WorkCenter,
  Iso4217,
  WorkOrderTarget
} from '../types'

export class ConfigContext<T extends string> {
  public readonly timezone: string
  public readonly plantId: string
  public readonly country: string
  public readonly pxTrendAvailable: boolean
  public readonly cceAvailable: boolean
  public readonly defaultCurrency: Iso4217
  public readonly workOrderTarget: WorkOrderTarget
  public readonly featureFlags: FeatureFlag<T>
  public readonly kilnStoppageCodes: DowntimeStoppageCode[]
  public readonly finishMillStoppageCodes: DowntimeStoppageCode[]
  public readonly rawMillStoppageCodes: DowntimeStoppageCode[]
  public readonly alternativeFuelInstallationStoppageCodes: DowntimeStoppageCode[]
  public readonly workCenters: WorkCenter[]
  public readonly shifts: Shift[]
  public readonly defaultTimeRange = 'currentShift'

  constructor(config: Config<T>) {
    this.timezone = config.timezone
    this.plantId = config.plantId
    this.country = config.country
    this.pxTrendAvailable = config.pxTrendAvailable
    this.cceAvailable = config.cceAvailable
    this.defaultCurrency = config.defaultCurrency
    this.workOrderTarget = config.workOrderTarget
    this.featureFlags = config.featureFlags
    this.shifts = config.shifts
    this.kilnStoppageCodes = sortBy(config.kilnStoppageCodes, 'description')
    this.finishMillStoppageCodes = sortBy(config.finishMillStoppageCodes, 'description')
    this.rawMillStoppageCodes = sortBy(config.rawMillStoppageCodes, 'description')
    this.alternativeFuelInstallationStoppageCodes = sortBy(
      config.alternativeFuelInstallationStoppageCodes,
      'description'
    )
    this.workCenters = config.workCenters
  }

  /**
   * Returns current time on the plant in the timezone of that plant. This is usefull for
   * start-of-day calculations (i.e. a day starts at midnight but the timezone of the plant needs
   * to be considered)
   */
  public plantNow: () => Moment = () => {
    return moment().tz(this.timezone)
  }
}
