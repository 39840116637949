import {MIME_TYPE, SUPPORTED_DOCUMENTS_FORMAT} from '../consts'

export const isDocumentSupported = ({name}: File) =>
  SUPPORTED_DOCUMENTS_FORMAT.split(',').some((format) => name.toLowerCase().endsWith(format))

export const isPdf = (fileName: string) => fileName.endsWith('.pdf')

export const getMimeType = (filename: string): string => {
  const extension = filename.split('.').pop()?.toLowerCase()
  return extension && MIME_TYPE[extension] ? MIME_TYPE[extension] : 'application/octet-stream'
}
