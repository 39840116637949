import moment, {Moment} from 'moment-timezone'

import {ProductForecastStatus} from '../../enums'
import {AuditFields} from '../common.types'
import {
  MaterialType,
  Status,
  MaterialSource,
  ProductType,
  RecipeComponentType,
  MaterialBoughtFrom
} from '../enum.types'
import {Nullified} from '../utility.types'

import {ClassificationDTO} from './materialClassification'

export type ProductForecastInfo = {
  status: ProductForecastStatus
}

export type ProductsForecastInfo = Record<string, ProductForecastInfo>

type MaterialBEStatus = Status.Created | Status.Edited

export interface Product {
  id: number
  code: string
  name: string
  type: ProductType
}

export interface AutocompleteOption {
  id: string
  label: string
}

export interface NewProduct extends Nullified<Omit<Product, 'type'>> {
  type: ProductType
}

export type MaterialsProducts = Record<ProductType, Product[]>

export interface GlobalMaterial {
  id: number
  name: string
  type: MaterialType
}

export interface ComponentMaterial {
  id: number
  name: string
}

export interface RecipeComponent {
  id: number
  type: RecipeComponentType
  fraction: number
  material: ComponentMaterial
}

export type RecipeComponentDTO = Omit<RecipeComponent, 'id'> & {id: number | null}

export interface Recipe {
  id: number
  name: string
  mainMaterialId: number
  status: MaterialBEStatus
  components: RecipeComponent[]
}

export type RecipeDTO = Omit<Recipe, 'id' | 'components'> & {
  id: number | null
  components: RecipeComponentDTO[]
}

export interface LimsMaterial {
  id: number
  code: string
  name?: string
}

export type NewLimsMaterial = Nullified<LimsMaterial>

interface MaterialOrigin {
  source: MaterialSource
  boughtFrom?: MaterialBoughtFrom
}

export interface Material extends ComponentMaterial, AuditFields {
  description?: string
  origin: MaterialOrigin
  status: MaterialBEStatus
  type: MaterialType
  // TODO <HCP-82759>: source will be removed after we fully switch to origin field
  source: MaterialSource
  products: Product[]
  // TODO <HCP-82759>: global material will be removed after material classification is implemented
  globalMaterialId?: number
  globalName: string
  limsMaterials: LimsMaterial[]
  pxTrendCounters: string[]
  classification?: ClassificationDTO
}

export class MaterialVM {
  public id: number
  public name: string
  public createdOn: Moment
  public createdBy: string
  public updatedOn?: Moment
  public updatedBy?: string
  public status: MaterialBEStatus
  public type: MaterialType
  public source: MaterialSource
  public products: Product[]
  public pxTrendCounters?: string[]

  constructor(data: Material, timezone: string) {
    this.id = data.id
    this.name = data.name
    this.createdOn = moment.utc(data.createdOn).tz(timezone)
    this.createdBy = data.createdBy
    this.updatedOn = data.updatedOn ? moment.utc(data.updatedOn).tz(timezone) : undefined
    this.updatedBy = data.updatedBy
    this.status = data.status
    this.type = data.type
    this.source = data.source
    this.products = data.products
    this.pxTrendCounters = data.pxTrendCounters
  }
}

export interface AddMaterialDTO {
  type: MaterialType
  name: string
  description: string
  source: MaterialSource
  boughtFrom?: MaterialBoughtFrom
}

export type EditMaterialDTO = Omit<AddMaterialDTO, 'type'> & {
  classification?: ClassificationDTO
  products?: Product[]
  limsMaterials?: LimsMaterial[]
  recipes?: RecipeDTO[]
  pxTrendCounters?: string[]
}

export interface MaterialDTO {
  type: MaterialType
  name: string
  source: MaterialSource
}

export interface NewMaterial extends MaterialDTO {
  status: Status.New
}

export type SelectedMaterial = Material | NewMaterial

export interface MaterialWithRecipes extends Material {
  recipes: Recipe[]
}

export type MaterialsHistory = Record<string, MaterialWithRecipes[]>
