import {useEffect} from 'react'

export const useOnPageWithoutUnmountFocus = <T>(callback: () => Promise<T> | void) => {
  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout> | undefined

    const onWindowFocus = () => {
      timeoutId = setTimeout(() => {
        void callback()
      }, 50)
    }

    window.addEventListener('visibilitychange', onWindowFocus, false)
    window.addEventListener('focus', onWindowFocus, false)

    return () => {
      clearTimeout(timeoutId)
      window.removeEventListener('visibilitychange', onWindowFocus)
      window.removeEventListener('focus', onWindowFocus)
    }
  }, [callback])
}
