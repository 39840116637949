import {SimpleDateTimePicker} from '@hconnect/common/components/shiftEventFormFields'
import {REGULAR_SIZE} from '@hconnect/common/consts'
import {Box, Grid, Switch} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {getShift} from '../../common/utils/shift'
import {useConfig} from '../../hooks/useConfig'
import {Schedule} from '../../types/shiftHandover.types'

type ScheduleSwitchProps<T> = {
  item: Partial<T>
  onChange: (value?: Schedule) => void
  errorText?: string
  disabled?: boolean
}

export function ScheduleSwitch<T extends {schedule?: Schedule}>({
  item,
  onChange,
  errorText,
  disabled
}: ScheduleSwitchProps<T>) {
  const {t} = useTranslation()
  const config = useConfig()
  const currentShift = getShift(config.plantNow(), config.shifts)
  const startShift = currentShift.startDate

  const onToggle = () => {
    if (item.schedule) {
      onChange(undefined)
    } else {
      onChange({
        startDate: startShift,
        endDate: undefined
      })
    }
  }

  return (
    <>
      <Grid item xs={12}>
        <Box ml={2} mt={1}>
          {t('shiftEvent.action.schedule')}
          <Switch
            data-test-id="schedule-event-switch"
            color="primary"
            checked={!!item.schedule}
            onChange={onToggle}
            disabled={disabled}
          />
        </Box>
      </Grid>
      {!!item.schedule && (
        <>
          <Grid item {...REGULAR_SIZE}>
            <SimpleDateTimePicker
              label={t('shiftEvent.label.startDate')}
              date={item.schedule.startDate ? item.schedule.startDate : startShift}
              required={true}
              handleDateChange={(date) => {
                if (date) {
                  onChange({
                    startDate: date,
                    endDate: item.schedule?.endDate
                  })
                }
              }}
              variant="filled"
              disabled={disabled}
              timezone={config.timezone}
            />
          </Grid>
          <Grid item {...REGULAR_SIZE}>
            <SimpleDateTimePicker
              label={t('shiftEvent.label.endDate')}
              date={item.schedule.endDate ? item.schedule.endDate : null}
              required={!!item.schedule}
              errorText={errorText}
              handleDateChange={(date) => {
                if (item.schedule) {
                  onChange({
                    startDate: item.schedule.startDate,
                    endDate: date || undefined
                  })
                }
              }}
              disabled={disabled}
              timezone={config.timezone}
            />
          </Grid>
        </>
      )}
    </>
  )
}
