import {Tag} from '@hconnect/common/components/Tag'
import {DateFormat} from '@hconnect/uikit'
import {CardBox} from '@hconnect/uikit/src/lib2'
import {EditOutlined, DeleteOutlined, Close} from '@mui/icons-material'
import {Box, Grid, IconButton, Typography} from '@mui/material'
import React, {FC, useCallback, useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import {useConfig} from '../../hooks/useConfig'
import {Instruction} from '../../types/documents.types'
import {EventProcessStageTitle} from '../common/EventProcessStageTitle'
import {MenuButton, MenuConfig} from '../common/Menu'

import {DocumentLink} from './DocumentLink'
import {DocumentStatus} from './DocumentStatus'

type DocumentDetailsProps = {
  document: Instruction
  onClose: () => void
  onDelete: () => void
  onEdit: (id: string) => void
}

export const DocumentDetails: FC<DocumentDetailsProps> = ({
  document,
  onEdit,
  onDelete,
  onClose
}) => {
  const {t} = useTranslation()
  const {timezone} = useConfig()

  const onEditClick = useCallback(() => {
    onEdit(document.id)
  }, [onEdit, document.id])

  const menuConfig = useMemo<MenuConfig>(
    () => [
      {
        dataTestId: 'document-edit-button',
        label: t('documents.action.edit'),
        onClick: onEditClick,
        icon: <EditOutlined sx={{mr: 2, verticalAlign: 'text-bottom'}} />
      },
      {
        dataTestId: 'document-delete-button',
        label: t('documents.action.delete'),
        onClick: onDelete,
        icon: <DeleteOutlined sx={{mr: 2, verticalAlign: 'text-bottom'}} />
      }
    ],
    [onDelete, onEditClick, t]
  )

  return (
    <CardBox data-test-id="document-details">
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
        <Typography variant="h3">{t('documents.label.details')}</Typography>

        <Box>
          <MenuButton
            config={menuConfig}
            menuDataTestId="document-details-more-menu"
            data-test-id="document-details-more-button"
          />
          <IconButton
            sx={{p: 1, ml: 3}}
            onClick={onClose}
            color="primary"
            data-test-id="document-details-close-button"
          >
            <Close />
          </IconButton>
        </Box>
      </Box>

      <Grid
        container
        sx={{
          my: 1.5
        }}
        spacing={2}
      >
        <Grid item xs={12}>
          <DocumentLink document={document} noUnderline={true} gap={1} iconSize={20} />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Tag labelKey="documents.label.processStage" data-test-id="document-detail-processStage">
            {!document.processStage ? (
              t('shiftEvent.action.noneOfThat')
            ) : (
              <EventProcessStageTitle stage={document.processStage} />
            )}
          </Tag>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Tag labelKey="documents.label.category" data-test-id="document-detail-category">
            {document.category ? t(`shiftEvent.category.${document.category}`) : '-'}
          </Tag>
        </Grid>

        <Grid item xs={12}>
          <Tag labelKey="documents.label.valid" data-test-id="document-detail-valid">
            <DateFormat date={document.validFrom} timezone={timezone} />
            {document.validTo && (
              <>
                {' - '}
                <DateFormat date={document.validTo} timezone={timezone} />
              </>
            )}
          </Tag>
        </Grid>

        <Grid item xs={12}>
          <Tag
            descriptionSx={{height: 40}}
            labelKey="documents.label.status"
            data-test-id="document-detail-status"
          >
            <DocumentStatus document={document} />
          </Tag>
        </Grid>
      </Grid>
    </CardBox>
  )
}
