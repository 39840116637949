import {mapRunningTimes} from '@hconnect/common/mappers'
import {EquipmentRunningTimes, DateRange, PlantRunningTimesDto} from '@hconnect/common/types'
import {AxiosError} from 'axios'
import {useQuery, UseQueryOptions} from 'react-query'

import {useConfig} from '../useConfig'

import {useApi} from './useApi'

const QueryKey = 'running-times'

export const useRunningTimes = (
  timeRange: DateRange,
  options?: UseQueryOptions<PlantRunningTimesDto[], AxiosError, EquipmentRunningTimes[]>
) => {
  const {axiosInstance} = useApi()
  const {plantId, timezone} = useConfig()

  return useQuery<PlantRunningTimesDto[], AxiosError, EquipmentRunningTimes[]>(
    [QueryKey, timeRange, plantId, timezone],
    async ({signal}) => {
      const response = await axiosInstance.get<PlantRunningTimesDto[]>(
        '/runningtimes-orchestrator/running-times',
        {
          params: {
            from: timeRange.startDate.toISOString(),
            to: timeRange.endDate.toISOString(),
            plantIds: [plantId]
          },
          signal
        }
      )
      return response.data || []
    },
    {
      ...options,
      select: (data): EquipmentRunningTimes[] =>
        data
          .flatMap(({equipmentsWithRunningTimes}) => equipmentsWithRunningTimes)
          .map((data) => ({
            ...data,
            runningTimes: mapRunningTimes(data.runningTimes, timezone)
          }))
    }
  )
}
