import {AttachmentFile, Category} from '@hconnect/common/types'
import {Moment} from 'moment-timezone'

import {DateUTC} from './atomic.types'

export enum InstructionStatus {
  UPCOMING = 'UPCOMING',
  ACTIVE = 'ACTIVE',
  EXPIRED = 'EXPIRED'
}

export enum InstructionType {
  LINK = 'LINK',
  PDF = 'PDF'
}

export type InstructionDto = {
  id: string
  title: string
  processStage: string
  category?: Category
  validFrom: DateUTC
  validTo?: DateUTC
  attachment?: string
  attachmentFileName?: string
  link?: string
  createdAt: DateUTC
  updatedAt: DateUTC
}

export type InstructionsDto = InstructionDto[]

export type Instruction = {
  id: string
  title: string
  processStage: string
  category?: Category
  validFrom: Moment
  validTo?: Moment
  attachment?: string
  attachmentFileName?: string
  link?: string
  createdAt: Moment
  updatedAt: Moment
  status: InstructionStatus
  type: InstructionType
  deleteDayCounter?: number
}

export type DocumentUpdate = Instruction & {
  newAttachment?: AttachmentFile
}

export type PossibleErrors = keyof DocumentUpdate

export type InstructionPayload = {
  title: string
  processStage: string
  category?: Category
  validFrom: DateUTC
  validTo?: DateUTC
  attachment?: AttachmentFile
  link?: string
}

export type DocumentSearchCriteria = {
  freeText?: string
  processStage?: string
  status?: InstructionStatus
  category?: Category
}
