import {LoadingSwitcher} from '@hconnect/uikit'
import React, {useCallback, FC} from 'react'

import {useInstructionEdit} from '../../hooks/api/useInstructionEdit'
import {DocumentUpdate, Instruction} from '../../types/documents.types'

import {DocumentFormContainer} from './DocumentFormContainer'

type DocumentEditProps = {
  doClose: () => void
  onSuccessSubmit: () => void
  document: Instruction
}

export const DocumentEdit: FC<DocumentEditProps> = ({onSuccessSubmit, doClose, document}) => {
  const editAction = useInstructionEdit({
    onSuccess: onSuccessSubmit
  })

  const doSubmit = useCallback(
    (item: DocumentUpdate) => {
      editAction.mutate(item)
    },
    [editAction]
  )

  return (
    <LoadingSwitcher isLoading={editAction.isLoading}>
      <DocumentFormContainer doClose={doClose} doSubmit={doSubmit} item={document} />
    </LoadingSwitcher>
  )
}
