import {Theme, useMediaQuery, useTheme} from '@mui/material'
import {RefObject, useCallback} from 'react'

export const useScrollToElement = (
  scrollToElementRef: RefObject<HTMLDivElement>,
  pageHeaderId?: string
) => {
  const theme = useTheme()
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

  return useCallback(() => {
    if (scrollToElementRef?.current && window.scrollY) {
      const offset = isMobile ? 0 : parseInt(theme.spacing(1))
      const headerOffsetHeight =
        (pageHeaderId && document.getElementById(pageHeaderId)?.offsetHeight) || 0
      const top = scrollToElementRef.current.offsetTop - (headerOffsetHeight + offset)
      const topRelativeToScreen = scrollToElementRef.current.getBoundingClientRect().top

      if (window.scrollY > top || topRelativeToScreen > window.innerHeight / 2) {
        window.scrollTo({
          left: 0,
          top,
          behavior: 'smooth'
        })
      }
    }
  }, [pageHeaderId, scrollToElementRef, theme, isMobile])
}
