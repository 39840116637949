import {
  CloseOutlined,
  OpenInNewOutlined,
  InsertDriveFileOutlined,
  CheckOutlined,
  HourglassEmptyOutlined
} from '@mui/icons-material'
import React, {ReactNode} from 'react'

import {InstructionStatus, InstructionType} from '../types/documents.types'

const ICON_SIZE_IN_PX = 16
export const SUPPORTED_DOCUMENTS_FORMAT = '.pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx'
export const INSTRUCTIONS_TENANT_ID = 'shiftHandover'

export const DOCUMENT_ICON_MAP: Record<InstructionType, ReactNode> = {
  [InstructionType.LINK]: <OpenInNewOutlined style={{fontSize: 'inherit'}} />,
  [InstructionType.PDF]: <InsertDriveFileOutlined style={{fontSize: 'inherit'}} />
}

export const STATUS_ICON_MAP: Record<InstructionStatus, ReactNode> = {
  [InstructionStatus.ACTIVE]: (
    <CheckOutlined sx={{fontSize: ICON_SIZE_IN_PX, color: 'success.main'}} />
  ),
  [InstructionStatus.UPCOMING]: (
    <HourglassEmptyOutlined sx={{fontSize: ICON_SIZE_IN_PX, color: 'text.secondary'}} />
  ),
  [InstructionStatus.EXPIRED]: (
    <CloseOutlined sx={{fontSize: ICON_SIZE_IN_PX, color: 'error.main'}} />
  )
}

export const STATUS_TRANSLATION_KEY_MAP: Record<InstructionStatus, string> = {
  [InstructionStatus.ACTIVE]: 'documents.statuses.active',
  [InstructionStatus.UPCOMING]: 'documents.statuses.upcoming',
  [InstructionStatus.EXPIRED]: 'documents.statuses.expired'
}

export const MIME_TYPE: Record<string, string> = {
  pdf: 'application/pdf',
  doc: 'application/msword',
  docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  xls: 'application/vnd.ms-excel',
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  ppt: 'application/vnd.ms-powerpoint',
  pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation'
}

export const ATTACHMENT_ID = 0
