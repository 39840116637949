import {Box, Button, TextField, useTheme, Stack, SxProps, Theme} from '@mui/material'
import {Moment} from 'moment-timezone'
import React, {useCallback, useEffect, useMemo, useState, ChangeEvent} from 'react'
import {useTranslation} from 'react-i18next'

import {Dates} from '../../../common'

import {
  DATE_FORMAT_FROM_INPUT,
  getDateObjectFromInput,
  getDateValue,
  getTimeValue,
  isDateValid,
  TIME_FORMAT_FROM_INPUT
} from './helpers'

const inputSx: SxProps<Theme> = {
  borderRadius: 1,
  height: 48,
  flexShrink: 0,
  background: 'rgba(0,0,0,0.01)',
  '&input': {
    padding: '13px 13px 5px'
  },
  '&input:valid + fieldset > legend > span': {
    display: 'none'
  }
}

export interface PickerFormProps {
  applyDateRange: (dates: Dates) => void
  dateExceptions?: Moment[]
  isOutsideRange?: (arg: Moment) => boolean
  isSingleDate?: boolean
  endDate?: Moment | null
  startDate: Moment | null
  dateFormatter?: (d: Moment) => string
  timeFormatter?: (d: Moment) => string
  timeZone: string
}

const PickerForm: React.FC<PickerFormProps> = ({
  applyDateRange,
  endDate: initialEndDate,
  dateExceptions,
  isOutsideRange,
  isSingleDate,
  startDate: initialStartDate,
  dateFormatter,
  timeFormatter,
  timeZone: timezone
}) => {
  const theme = useTheme()
  const {t} = useTranslation()
  const dateFormatterDefault = useCallback(
    (_date: Moment) =>
      dateFormatter ? dateFormatter(_date) : _date.tz(timezone).format(DATE_FORMAT_FROM_INPUT),
    [dateFormatter, timezone]
  )
  const [startDate, setStartDate] = useState<string>(
    initialStartDate ? dateFormatterDefault(initialStartDate) : ''
  )
  const [endDate, setEndDate] = useState<string>(
    initialEndDate ? dateFormatterDefault(initialEndDate) : ''
  )
  const timeFormatterDefault = useCallback(
    (_time: Moment) =>
      timeFormatter ? timeFormatter(_time) : _time.format(TIME_FORMAT_FROM_INPUT),
    [timeFormatter]
  )
  const [startTime, setStartTime] = useState<string>(
    initialStartDate ? timeFormatterDefault(initialStartDate) : '00:00'
  )
  const [endTime, setEndTime] = useState<string>(
    initialEndDate ? timeFormatterDefault(initialEndDate) : '00:00'
  )
  const isInputValid = useMemo(
    () =>
      isSingleDate
        ? isDateValid({
            date: startDate,
            time: startTime,
            exceptions: dateExceptions,
            isOutsideRange,
            timezone
          })
        : isDateValid({date: startDate, time: startTime, isOutsideRange, timezone}) &&
          isDateValid({date: endDate, time: endTime, isOutsideRange, timezone}) &&
          getDateObjectFromInput(endDate, endTime, timezone) >
            getDateObjectFromInput(startDate, startTime, timezone),
    [dateExceptions, endDate, endTime, isOutsideRange, isSingleDate, startDate, startTime, timezone]
  )

  const handleDateChange = useCallback(
    ({target}: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const value = getDateValue(target.value)
      target.name === 'startDate' ? setStartDate(value) : setEndDate(value)
    },
    [setStartDate]
  )

  const handleTimeChange = useCallback(
    ({target}: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const value = getTimeValue(target.value)
      target.name === 'startTime' ? setStartTime(value) : setEndTime(value)
    },
    [setStartTime]
  )

  const clearInput = useCallback(() => {
    setStartDate('')
    setEndDate('')
    setStartTime('')
    setEndTime('')
  }, [])

  const apply = useCallback(() => {
    applyDateRange({
      startDate: getDateObjectFromInput(startDate, startTime, timezone) || null,
      endDate: getDateObjectFromInput(endDate, endTime, timezone) || null
    })
  }, [applyDateRange, endDate, endTime, startDate, startTime, timezone])

  useEffect(() => {
    setStartDate(initialStartDate ? dateFormatterDefault(initialStartDate) : '')
    setEndDate(initialEndDate ? dateFormatterDefault(initialEndDate) : '')
  }, [initialStartDate, initialEndDate, dateFormatterDefault])

  return (
    <Stack spacing={2}>
      <Stack spacing={2} direction="row" data-test-id="time-range-inputs">
        <TextField
          variant="outlined"
          name="startDate"
          placeholder="09.01.2021"
          label={t('datePicker.startDate')}
          value={startDate}
          onChange={handleDateChange}
          InputProps={{
            autoComplete: 'off',
            sx: inputSx
          }}
          InputLabelProps={{
            sx: {
              color: theme.palette.text.primarySoft
            }
          }}
        />
        <TextField
          variant="outlined"
          name="startTime"
          placeholder="00:00"
          label={t('datePicker.startTime')}
          value={startTime}
          onChange={handleTimeChange}
          InputProps={{
            autoComplete: 'off',
            sx: inputSx
          }}
          InputLabelProps={{
            sx: {
              color: theme.palette.text.primarySoft
            }
          }}
        />
      </Stack>
      {!isSingleDate && (
        <Stack spacing={2} direction="row" data-test-id="time-range-inputs">
          <TextField
            variant="outlined"
            name="endDate"
            placeholder="09.01.2021"
            label={t('datePicker.endDate')}
            value={endDate}
            onChange={handleDateChange}
            InputProps={{
              autoComplete: 'off',
              sx: inputSx
            }}
            InputLabelProps={{
              sx: {
                color: theme.palette.text.primarySoft
              }
            }}
          />
          <TextField
            variant="outlined"
            name="endTime"
            placeholder="00:00"
            label={t('datePicker.endTime')}
            value={endTime}
            onChange={handleTimeChange}
            InputProps={{
              autoComplete: 'off',
              sx: inputSx
            }}
            InputLabelProps={{
              sx: {
                color: theme.palette.text.primarySoft
              }
            }}
          />
        </Stack>
      )}
      <Box sx={{display: 'flex', justifyContent: 'center'}}>
        <Button
          sx={{
            padding: 1,
            textTransform: 'none',
            '&:first-of-type': {
              marginRight: 2
            },
            color: theme.palette.primary.main
          }}
          onClick={clearInput}
          color={'secondary'}
          variant="outlined"
          data-test-id="range-delete"
        >
          {t('datePicker.delete')}
        </Button>
        <Button
          sx={{
            padding: 1,
            textTransform: 'none',
            '&:first-of-type': {
              marginRight: 20
            }
          }}
          onClick={apply}
          disabled={!isInputValid}
          color={'primary'}
          data-test-id="range-apply"
        >
          {t('datePicker.apply')}
        </Button>
      </Box>
    </Stack>
  )
}

export {PickerForm}
