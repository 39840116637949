import {AxiosError} from 'axios'
import {useQuery, UseQueryOptions} from 'react-query'

import {FilterOptions} from '../../common/utils/filterParameter'
import {mapShiftEvent} from '../../mappers'
import {ShiftEventDto, Task} from '../../types/shiftHandover.types'
import {useConfig} from '../useConfig'

import {TaskSchedulesResult, TaskSchedulesResultDto} from './types'
import {useApi} from './useApi'

const QUERY_KEY = 'repetitive-tasks'

const mapRepetitiveTasks = (
  response: TaskSchedulesResultDto,
  timezone: string
): TaskSchedulesResult => {
  return {
    ...response,
    taskSchedules: response.taskSchedules.map(
      (task) => mapShiftEvent(task as ShiftEventDto, timezone) as Task
    )
  }
}

export const useRepetitiveTasks = (
  filters: FilterOptions,
  itemsPerPage = 50,
  pageNumber = 0,
  options?: UseQueryOptions<TaskSchedulesResult, AxiosError>
) => {
  const {axiosInstance} = useApi()
  const {plantId, timezone} = useConfig()
  const params = {
    itemsPerPage,
    pageNumber,
    ...filters
  }
  const path = `/shifthandover/${plantId}/search/taskschedules`

  return useQuery<TaskSchedulesResult, AxiosError>(
    [QUERY_KEY, filters, params, plantId, timezone],
    async ({signal}) => {
      const response = await axiosInstance.post<TaskSchedulesResultDto>(path, params, {signal})
      return mapRepetitiveTasks(response.data, timezone)
    },
    options
  )
}
