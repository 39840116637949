import {EventStatusLabel} from '@hconnect/common/components/eventProperties/EventStatusLabel'
import {OverdueLabel} from '@hconnect/common/components/eventProperties/OverdueLabel'
import {EquipmentLabel, EventTypeLabel} from '@hconnect/common/components/shiftEventLabels'
import {FIX_COLUMN_WIDTH, FIX_COLUMN_WIDTH_XS} from '@hconnect/common/consts'
import {getHighlightedTitle} from '@hconnect/common/utils/highlightHelpers'
import type {Column} from '@hconnect/uikit/src/lib2'
import {mergeSx} from '@hconnect/uikit/src/lib2'
import {SxProps, Theme, Typography} from '@mui/material'
import {TFunction} from 'i18next'
import {isEmpty} from 'lodash'
import {Moment} from 'moment-timezone'
import React from 'react'

import {isStoppage, isTask} from '../common/utils/eventType'
import {ShiftEvent, Task} from '../types/shiftHandover.types'
import {Equipment} from '../types/workOrders.types'
import {formatDateTime} from '../utils'

import {CommentCounter} from './common/CommentCounter'

type SecondaryProps = {
  sx?: SxProps<Theme>
  children: React.ReactNode | string
}

export const Secondary: React.FC<SecondaryProps> = ({children, sx}) => {
  return (
    <Typography variant="body1" sx={mergeSx({fontWeight: 500}, sx)}>
      {children}
    </Typography>
  )
}

type LabelProps = {
  sx?: SxProps<Theme>
  children: React.ReactNode | string
}

export const Label = ({children, sx}: LabelProps) => {
  return <Typography sx={mergeSx({fontSize: 12, lineHeight: '16px'}, sx)}>{children}</Typography>
}

type GenerateTitleArguments = {
  translation: TFunction
  type?: 'withEquipment' | 'regular'
  markOverdueTask?: boolean
  freeSearchText?: string
  plantNow: Moment
}

export enum ColumnKeys {
  EventType = 'eventType',
  Title = 'title',
  EquipmentNumber = 'equipmentNumber',
  MainEquipmentNumber = 'mainEquipmentNumber',
  Category = 'category',
  Created = 'created',
  MaintenanceNotification = 'maintenanceNotification',
  Status = 'status'
}
enum NotSortableColumnKey {
  CommentCount = 'commentCount'
}

export const generateTitleColumn = ({
  translation: t,
  type,
  markOverdueTask = true,
  freeSearchText,
  plantNow
}: GenerateTitleArguments): Column<ShiftEvent> => {
  const title =
    type === 'withEquipment'
      ? `${t('shiftEvent.label.equipment')}: ${t('shiftEvent.label.title')}`
      : t('shiftEvent.label.title')

  return {
    key: ColumnKeys.Title,
    label: <Label>{title}</Label>,
    flex: 4,
    sortable: true,
    customTemplate: (item: ShiftEvent) => (
      <Typography
        variant="h4"
        color="textPrimary"
        sx={{fontSize: 16, fontWeight: 500, wordBreak: 'break-word'}}
      >
        {type === 'withEquipment' && item.equipment ? (
          <>
            <EquipmentLabel withId equipment={item.equipment} />: {item.title}
          </>
        ) : (
          <>
            {freeSearchText ? getHighlightedTitle(item.title, freeSearchText) : item.title}{' '}
            {markOverdueTask && isTask(item) && (
              <OverdueLabel status={item.status} dueDate={item.dueDate} plantNow={plantNow} />
            )}
          </>
        )}
      </Typography>
    )
  }
}

export const generateCategoryColumn = (t: TFunction) => ({
  key: ColumnKeys.Category,
  label: <Label>{t('shiftEvent.label.category')}</Label>,
  width: FIX_COLUMN_WIDTH,
  sortable: true,
  labelKey: 'shiftEvent.label.category',
  customTemplate: (item: ShiftEvent) => (
    <Secondary>
      {item.category
        ? t(`shiftEvent.category.${item.category}`)
        : t('shiftEvent.action.noneOfThat')}
    </Secondary>
  )
})

export const generateCommentsColumn = () => ({
  key: NotSortableColumnKey.CommentCount,
  width: FIX_COLUMN_WIDTH_XS,
  labelKey: 'shiftEvent.label.comments',
  customTemplate: (item: ShiftEvent) => <CommentCounter id={item.id} count={item.commentCount} />
})

export const generateEquipmentNumberColumn = (t: TFunction): Column<ShiftEvent> => ({
  key: ColumnKeys.EquipmentNumber,
  label: <Label>{t('shiftEvent.label.equipment')}</Label>,
  flex: 2,
  minWidth: FIX_COLUMN_WIDTH,
  sortable: true,
  customTemplate: ({equipment}: ShiftEvent) => {
    return (
      <Secondary>
        {!isEmpty(equipment) ? (
          <EquipmentLabel withId withText withTooltip withEllipsis equipment={equipment} />
        ) : (
          ''
        )}
      </Secondary>
    )
  }
})

export const generateMainEquipmentNumberColumn = (t: TFunction): Column<ShiftEvent> => ({
  key: ColumnKeys.MainEquipmentNumber,
  label: <Label>{t('shiftEvent.label.mainEquipment')}</Label>,
  flex: 2,
  minWidth: FIX_COLUMN_WIDTH,
  sortable: true,
  customTemplate: ({mainEquipment}: ShiftEvent) => {
    return (
      <Secondary>
        {!isEmpty(mainEquipment) ? (
          <EquipmentLabel withId withText withTooltip withEllipsis equipment={mainEquipment} />
        ) : (
          ''
        )}
      </Secondary>
    )
  }
})

export const generateStatusColumn = (
  t: TFunction,
  type?: 'small' | 'regular'
): Column<ShiftEvent> => ({
  headProps: type === 'small' ? {style: {paddingLeft: '4px'}} : {},
  key: ColumnKeys.Status,
  width: type === 'small' ? '0px' : undefined,
  label: <Label>{t('shiftEvent.label.status')}</Label>,
  sortable: true,
  customTemplate: (item: ShiftEvent) => (
    <Secondary>
      <EventStatusLabel status={item.status} iconOnly={type === 'small'} />
    </Secondary>
  )
})

export const generateDueDateColumn = (t: TFunction): Column<ShiftEvent> => ({
  key: 'dueDate',
  label: <Label>{t('shiftEvent.label.dueDate')}</Label>,
  flex: 2,
  sortable: true,
  customTemplate: (item: ShiftEvent) => (
    <Secondary sx={{whiteSpace: 'nowrap'}}>{formatDateTime((item as Task).dueDate)}</Secondary>
  )
})

export const generateMaintenanceNotificationColumn = (
  t: TFunction,
  freeSearchText: string | undefined
): Column<ShiftEvent> => ({
  key: ColumnKeys.MaintenanceNotification,
  label: <Label>{t('shiftEvent.label.maintenanceNotification')}</Label>,
  flex: 2,
  sortable: true,
  customTemplate: (item: ShiftEvent) => {
    return (
      <Secondary>
        {freeSearchText && item.sapMaintenanceNotificationId
          ? getHighlightedTitle(item.sapMaintenanceNotificationId, freeSearchText)
          : item.sapMaintenanceNotificationId || '-'}
      </Secondary>
    )
  }
})

export const generateCreatedAtColumn = (t: TFunction): Column<ShiftEvent> => ({
  key: ColumnKeys.Created,
  label: <Label>{t('shiftEvent.label.createdAt')}</Label>,
  flex: 2,
  sortable: true,
  customTemplate: (item: ShiftEvent) => (
    <Secondary sx={{whiteSpace: 'nowrap'}}>
      {isStoppage(item)
        ? formatDateTime(item.stoppageStart)
        : isTask(item)
          ? formatDateTime(item.dueDate)
          : formatDateTime(item.created)}
    </Secondary>
  )
})

export const generateEventTypeColum = (
  t: TFunction,
  type?: 'small' | 'regular'
): Column<ShiftEvent> => ({
  key: ColumnKeys.EventType,
  width: type === 'small' ? '0px' : undefined,
  label: <Label sx={{whiteSpace: 'nowrap'}}>{t('shiftEvent.label.eventType')}</Label>,
  sortable: true,
  customTemplate: (item: ShiftEvent) => (
    <Secondary>
      <EventTypeLabel eventType={item.eventType} iconOnly={type === 'small'} />
    </Secondary>
  )
})

export const generateWorkOrderEquipmentNumberColumn = <T extends {equipment?: Equipment}>(
  type: 'small' | 'regular',
  t: TFunction,
  freeSearchText?: string
): Column<T> => ({
  key: ColumnKeys.EquipmentNumber,
  sortable: true,
  label: <Label>{t('shiftEvent.label.equipment')}</Label>,
  minWidth: FIX_COLUMN_WIDTH,
  customTemplate: ({equipment}: T) => {
    return (
      <Secondary>
        {!isEmpty(equipment) ? (
          <EquipmentLabel
            fullWidth={type === 'regular'}
            withId
            withText
            withTooltip
            withEllipsis
            equipment={equipment}
            freeSearchText={freeSearchText}
          />
        ) : (
          ''
        )}
      </Secondary>
    )
  }
})

export const getTitleColumn = <T extends {title?: string}>(
  t: TFunction,
  freeSearchText?: string
): Column<T> => ({
  key: 'title',
  sortable: true,
  label: <Label>{t('shiftEvent.label.title')}</Label>,
  customTemplate: ({title}) => (
    <Secondary>
      {title ? (freeSearchText ? getHighlightedTitle(title, freeSearchText) : title) : '-'}
    </Secondary>
  )
})
