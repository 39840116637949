import {EventAttachmentsDisplay} from '@hconnect/common/components/attachments'
import {Attachment} from '@hconnect/common/types'
import {Box, Link, Typography} from '@mui/material'
import React, {FC, useState} from 'react'

import {DOCUMENT_ICON_MAP, ATTACHMENT_ID} from '../../consts'
import {Instruction} from '../../types/documents.types'
import {isPdf} from '../../utils'

import {DocumentDownloadLink} from './DocumentDownloadLink'

type DocumentLinkProps = {
  document: Instruction
  noUnderline?: boolean
  gap?: number
  iconSize?: number
}

export const DocumentLink: FC<DocumentLinkProps> = ({
  document: documentData,
  noUnderline,
  gap = 2,
  iconSize = 16
}) => {
  const [showPreview, setShowPreview] = useState<boolean>(false)

  const attachment: Attachment | undefined =
    documentData.attachment && documentData.attachmentFileName
      ? {
          id: ATTACHMENT_ID,
          fileName: documentData.attachmentFileName,
          mediaType: 'application/pdf',
          previewMediaType: 'application/pdf',
          previewUrl: documentData.attachment,
          url: documentData.attachment
        }
      : undefined

  return (
    <Box display="flex" alignItems="center" gap={gap}>
      <Box display="flex" fontSize={iconSize}>
        {DOCUMENT_ICON_MAP[documentData.type]}
      </Box>{' '}
      {documentData.link ? (
        <Link
          href={documentData.link}
          rel="noreferrer noopener"
          target="_blank"
          sx={[
            !!noUnderline && {textDecoration: 'none'},
            {lineHeight: '22px', wordBreak: 'break-word'}
          ]}
          download={!!documentData.attachmentFileName}
          data-test-id="document-link-title"
        >
          {documentData.title}
        </Link>
      ) : documentData.attachmentFileName &&
        documentData.attachment &&
        !isPdf(documentData.attachmentFileName) ? (
        <DocumentDownloadLink
          attachment={documentData.attachment}
          title={documentData.title}
          attachmentFileName={documentData.attachmentFileName}
          noUnderline={noUnderline}
        />
      ) : (
        <>
          <Link
            component={Typography}
            sx={[!!noUnderline && {textDecoration: 'none'}, {wordBreak: 'break-word'}]}
            data-test-id="document-title"
            onClick={() => setShowPreview(true)}
          >
            {documentData.title}
          </Link>
          {showPreview && attachment && (
            <EventAttachmentsDisplay
              attachments={[attachment]}
              activeAttachmentId={ATTACHMENT_ID}
              onClose={() => setShowPreview(false)}
            />
          )}
        </>
      )}
    </Box>
  )
}
