import {COCKPIT_EVENTS_PAGE_TAB_PARAM_KEY} from '@hconnect/common/consts'
import {EventsTab} from '@hconnect/common/types'
import {FC} from 'react'
import {useNavigate} from 'react-router'
import {generatePath} from 'react-router-dom'

import {EventsContainer} from '../containers/shiftEvents/EventsContainer'
import {NotificationsContainer} from '../containers/shiftEvents/NotificationsContainer'
import {WorkOrdersContainer} from '../containers/shiftEvents/WorkOrdersContainer'
import {useCancelInactiveQueries} from '../hooks/useCancelInactiveQueries'
import {useConfig} from '../hooks/useConfig'
import {useQueryParamValue} from '../hooks/useQueryParamValue'
import {routeToEvents} from '../routes'

const ID = 'shiftEventTab'
const KEY = COCKPIT_EVENTS_PAGE_TAB_PARAM_KEY
const KEYS = [KEY]

export const EventsPage: FC = () => {
  const {plantId} = useConfig()
  const navigate = useNavigate()
  useCancelInactiveQueries()

  const [eventTab = ''] = useQueryParamValue(ID, KEYS, {
    keepInLocalStorage: true
  })
  const activeTab = (new URLSearchParams(eventTab).get(KEY) ?? EventsTab.Events) as EventsTab

  const handleChange = (tab: EventsTab) => {
    // reset redundant query params
    const path = generatePath(routeToEvents, {plantId})
    navigate(`${path}?${KEY}=${tab}`)
  }

  if (activeTab === EventsTab.WorkOrders) {
    return <WorkOrdersContainer activeTab={activeTab} setActiveTab={handleChange} />
  }
  if (activeTab === EventsTab.Notifications) {
    return <NotificationsContainer activeTab={activeTab} setActiveTab={handleChange} />
  }
  return <EventsContainer activeTab={activeTab} setActiveTab={handleChange} />
}
