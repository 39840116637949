import {Material, MaterialVM} from '@hconnect/common/types'
import {AxiosError} from 'axios'
import {useQuery, UseQueryOptions} from 'react-query'

import {useConfig} from '../useConfig'

import {useApi} from './useApi'

const QueryKey = 'materials'

export const useMaterials = (
  snapshotDateTime: string,
  options?: UseQueryOptions<MaterialVM[], AxiosError>
) => {
  const {axiosInstance} = useApi()
  const {plantId, timezone} = useConfig()

  return useQuery<MaterialVM[], AxiosError>(
    [QueryKey, snapshotDateTime, plantId, timezone],
    async ({signal}) => {
      const response = await axiosInstance.get<Material[]>(
        `/plants/${plantId}/materials/history/${snapshotDateTime}`,
        {signal}
      )
      return (response.data || []).map((material) => new MaterialVM(material, timezone))
    },
    options
  )
}
