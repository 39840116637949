import {Box, Stack, Typography} from '@mui/material'
import React, {FC} from 'react'
import {useTranslation} from 'react-i18next'

import {STATUS_ICON_MAP, STATUS_TRANSLATION_KEY_MAP} from '../../consts'
import {Instruction} from '../../types/documents.types'

type DocumentStatusProps = {
  document: Instruction
}

export const DocumentStatus: FC<DocumentStatusProps> = ({document}) => {
  const {t} = useTranslation()

  return (
    <Stack justifyContent="space-around" justifyItems="center" lineHeight={1.5}>
      <Box display="flex" alignItems="center" gap={1}>
        {STATUS_ICON_MAP[document.status]} {t(STATUS_TRANSLATION_KEY_MAP[document.status])}
      </Box>
      {document.deleteDayCounter && (
        <Typography ml={3} variant="caption" whiteSpace="nowrap">
          {t('documents.label.deletedIn', {
            days: document.deleteDayCounter
          })}
        </Typography>
      )}
    </Stack>
  )
}
