import {ContentBox, ContentMode} from '@hconnect/common/components/ContentBox'
import {DashboardLink} from '@hconnect/common/components/downtime'
import {EquipmentRunningTimesResult} from '@hconnect/common/components/kpiPerformance/EquipmentRunningTimesResult'
import {OperatingHoursLegendList} from '@hconnect/common/components/runningTimes'
import {EquipmentRunningTimes, RunningTime, RunningTimesEquipmentData} from '@hconnect/common/types'
import {Box} from '@mui/material'
import {FC, ReactNode} from 'react'
import {useTranslation} from 'react-i18next'

type RunningTimesSummaryProps = {
  contentMode: ContentMode
  isLoading?: boolean
  runningTimePerformances?: EquipmentRunningTimes[]
  getStackedBarChartComponent?: (
    equipment: RunningTimesEquipmentData,
    runningTimes: RunningTime[]
  ) => ReactNode
  plantId: string
  isError: boolean
}

export const RunningTimesSummary: FC<RunningTimesSummaryProps> = ({
  contentMode,
  isLoading,
  runningTimePerformances,
  getStackedBarChartComponent,
  plantId,
  isError
}) => {
  const {t} = useTranslation()

  const errorMessage = isError ? t('error.label.fetchingError') : undefined

  return (
    <ContentBox
      title={
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          flexWrap="wrap"
          gap={1}
        >
          {t('summary.label.runningTimes')}
          <DashboardLink plantId={plantId} title={t('goToDashboard')} />
        </Box>
      }
      mode={contentMode}
      bodyWithPadding
      isLoading={isLoading}
      errorMessage={errorMessage}
      minHeight="450px"
      data-test-id="running-times-container"
    >
      <OperatingHoursLegendList />
      {runningTimePerformances?.map(
        (equipmentPerformanceData: EquipmentRunningTimes, index: number) => {
          return (
            <EquipmentRunningTimesResult
              equipment={equipmentPerformanceData.equipment}
              runningTimePerformance={equipmentPerformanceData}
              key={equipmentPerformanceData.equipment.id}
              getStackedBarChartComponent={getStackedBarChartComponent}
              hideDivider={runningTimePerformances?.length === index + 1}
            />
          )
        }
      )}
    </ContentBox>
  )
}
