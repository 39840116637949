import {useFile} from '@hconnect/common/hooks/useFile'
import {Link, Typography} from '@mui/material'
import React, {FC} from 'react'

import {ATTACHMENT_ID} from '../../consts'
import {getMimeType} from '../../utils'

type DocumentDownloadLinkProps = {
  attachment: string
  noUnderline?: boolean
  title: string
  attachmentFileName: string
}

export const DocumentDownloadLink: FC<DocumentDownloadLinkProps> = ({
  attachment,
  noUnderline,
  title,
  attachmentFileName
}) => {
  const mimeType = getMimeType(attachmentFileName)
  const {refetch} = useFile(attachment, mimeType, ATTACHMENT_ID, {
    enabled: false
  })

  const getFile = async () => {
    try {
      const {data: fileData} = await refetch()
      if (!fileData) {
        console.error('Failed to fetch file')
        return
      }

      const blob = new Blob([fileData], {type: mimeType})
      const url = URL.createObjectURL(blob)

      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', attachmentFileName)
      link.setAttribute('target', '_blank')
      link.setAttribute('rel', 'noopener noreferrer')
      link.click()

      URL.revokeObjectURL(url)
    } catch (err) {
      console.error('Error fetching the file:', err)
    }
  }

  return (
    <Link
      component={Typography}
      sx={[!!noUnderline && {textDecoration: 'none'}, {wordBreak: 'break-word'}]}
      data-test-id="document-title"
      onClick={() => void getFile()}
    >
      {title}
    </Link>
  )
}
