import {useEffect} from 'react'
import {useQueryClient} from 'react-query'

export const useCancelInactiveQueries = () => {
  const queryClient = useQueryClient()

  useEffect(() => {
    return () => {
      queryClient
        .getQueryCache()
        .getAll()
        .forEach((query) => {
          if (query.queryKey) {
            /**
             * 1. We cancel inactive queries when the user navigates to a new page.
             * 2. We check all the queries if there are observers attached and if not we can cancel the call.
             *    A query becomes inactive when it has no observers, which basically means:
             *    all components that use it via useQuery have unmounted.
             */
            queryClient.cancelQueries(query.queryKey, {inactive: true}).catch((error) => {
              console.error('Error canceling query:', error)
            })
          }
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.href])
}
