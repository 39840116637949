import {AxiosInstance} from 'axios'

import {prepareEventFormData} from '../../common/utils/formData'
import {getEventBackendEndpoint} from '../../consts'
import {mapShiftEvent} from '../../mappers'
import {EventUpdate, ShiftEvent, ShiftEventDto} from '../../types/shiftHandover.types'

export const updateEvent = async (
  axiosInstance: AxiosInstance,
  plantId: string,
  event: EventUpdate,
  timezone: string,
  scheduleRecurringTask = false
): Promise<ShiftEvent> => {
  const formData = await prepareEventFormData(event, [
    'attachments',
    'checklists',
    ...(scheduleRecurringTask ? ['status'] : [])
  ])

  // to handle the specific case of series task (like changing the title of all of them at once)
  // the backend team decided to use a dedicated endpoint with the same DTO as for a single event
  const path = getEventBackendEndpoint(plantId, event.eventType, scheduleRecurringTask)

  const response = await axiosInstance.put<ShiftEventDto>(path, formData)
  return mapShiftEvent(response.data, timezone)
}
