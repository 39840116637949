import {formatFloat} from '@hconnect/uikit'
import {styled} from '@mui/material'
import React, {memo} from 'react'
import {useTranslation} from 'react-i18next'

import {KpisList} from '../../types'

const DELTA_MARGIN = 5

const commonDeltaCellCss = {
  padding: '3px 8px',
  borderRadius: 12
}

export const DeltaCellPositive = styled('span')(({theme}) => ({
  color: theme.palette.success.dark,
  background: 'rgba(0, 171, 80, 0.1)',
  ...commonDeltaCellCss
}))

export const DeltaCellNeutral = styled('span')(({theme}) => ({
  color: theme.palette.secondary.main,
  background: 'rgba(84, 112, 140, 0.1)',
  ...commonDeltaCellCss
}))

export const DeltaCellNegative = styled('span')(({theme}) => ({
  color: theme.palette.error.main,
  background: 'rgba(218, 9, 1, 0.1)',
  ...commonDeltaCellCss
}))

type DeltaLabel = {
  value?: number
  reverseColor?: boolean
}

/*
  Note: slight deviation in delta with +/-5%, should neutral form,
  positive is green and negative is red
  */
export const DeltaLabel = memo<DeltaLabel>(({value, reverseColor}) => {
  const {
    t,
    i18n: {language}
  } = useTranslation()

  if (value === undefined)
    return <>{t('performanceDashboard.actualVsPlanned.label.notApplicable')}</>

  const percentage = formatFloat(value, 1, language)

  if (value > -DELTA_MARGIN && value < DELTA_MARGIN) {
    return <DeltaCellNeutral>{percentage}%</DeltaCellNeutral>
  }
  if (reverseColor) {
    return value > 0 ? (
      <DeltaCellNegative>+{percentage}%</DeltaCellNegative>
    ) : (
      <DeltaCellPositive>{percentage}%</DeltaCellPositive>
    )
  }
  return value > 0 ? (
    <DeltaCellPositive>+{percentage}%</DeltaCellPositive>
  ) : (
    <DeltaCellNegative>{percentage}%</DeltaCellNegative>
  )
})

export const getDeltaBarColor = (value: number) =>
  value > -DELTA_MARGIN && value < DELTA_MARGIN
    ? 'primary.light'
    : value > 0
      ? 'success.light'
      : 'error.light'

export const kpiDeltaReversedColorMap: Record<KpisList, boolean> = {
  [KpisList.ClinkerProduction]: false,
  [KpisList.CementProduction]: false,
  [KpisList.KilnReliabilityCoefficient]: false,
  [KpisList.KilnOperatingCoefficient]: false,
  [KpisList.KilnHeatConsumption]: true,
  [KpisList.AlternativeFuelRate]: false,
  [KpisList.MTBF]: false,
  [KpisList.TechnicalCementPowerCons]: true
}
